import imageInput from '@/mixins/image-input.js';
import BookmakersPostModel from '../bookmakers/models/bookmakers-post.model';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new BookmakersPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("bookmakers/setItem", formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                }
            });
        }
    },
    mixins: [imageInput],
}
